import React from 'react'
import { WappContext, withWapp } from 'wapplr-react/dist/common/Wapp'

class Embed extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.refElements = {}

        this.state = {
            dataId: '',
            scriptUrl: '',
            script: null
        }

    }

    async componentDidMount() {

        const paths = window.location.pathname.split('/')
        let scriptUrl = paths[4]
        scriptUrl = decodeURIComponent(scriptUrl)

        if (paths[2] && paths[3] && scriptUrl) {

            await this.setState({
                dataId: paths[2] + '/' + paths[3],
                scriptUrl: scriptUrl
            })

            const script = document.createElement('script')
            script.setAttribute('src', scriptUrl)
            script.setAttribute('async', '')
            script.async = true

            document.body.appendChild(script)

        }

    }

    render() {

        const { dataId } = this.state

        return (
            <div>
                <div
                    id={'flatchooser-unique-id'}
                    data-app='flatchooser'
                    data-id={dataId}

                    data-autoload='1'
                    data-disablegraphql='1'
                    data-loadfromstatic='1'

                    data-template='design2'

                    style={{
                        width: '100%',
                        maxWidth: '100%',
                        height: 'auto',
                        display: 'block',
                        position: 'relative',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                ></div>
            </div>
        )
    }

}


Embed.contextType = WappContext

export default withWapp(Embed)
