import ContentCreateDemoPlayer from './contents/createdemoplayer'
import ContentEmbed from './contents/embed'
import ContentNorwegian from './contents/norwegian'
import ContentCreateDemoPlayerMartin from './contents/createdemoplayermartin'
import ContentOpenPlayerById from './contents/openbyid'
import ContentExample from './contents/createdemoplayerexample';

export default function setContents(p = {}) {

    const {wapp} = p;

    function getTitle({wapp, res, title = ""}) {
        const config = wapp.getTargetObject().config;
        const {siteName = "Wapplr"} = config;
        const {statusCode, statusMessage, errorMessage} = res.wappResponse;
        if (statusCode === 404) {
            title = statusMessage || "Not found";
        }
        if (statusCode === 500) {
            title = errorMessage || statusMessage || "Internal Server Error";
        }
        return (title) ? title + " | " + siteName : siteName
    }

    wapp.contents.add({
        "page": {
            render: ContentCreateDemoPlayer,
            renderType: "react",
            title: getTitle
        }
    });

    wapp.contents.add({
        "openbyid": {
            render: ContentOpenPlayerById,
            renderType: "react",
            title: getTitle
        }
    });

    wapp.contents.add({
        "embed": {
            render: ContentEmbed,
            renderType: "react",
            title: getTitle
        }
    });

    wapp.contents.add({
        "norwegian": {
            render: ContentNorwegian,
            renderType: "react",
            title: getTitle
        }
    });

    wapp.contents.add({
        "martin": {
            render: ContentCreateDemoPlayerMartin,
            renderType: "react",
            title: getTitle
        }
    });

    wapp.contents.add({
        "example": {
            render: ContentExample,
            renderType: "react",
            title: getTitle
        }
    });

    wapp.router.replace([{path: "/", contentName: "page" }]);
    wapp.router.replace([{path: "/offline", contentName: "page" }]);

    wapp.router.add([
        { path: "/page", contentName: "page" },
        { path: "/martin", contentName: "martin" },
        { path: "/example", contentName: "example" },
        { path: "/openbyid", contentName: "openbyid" },
        { path: "/embed/:userId/:playerId/:scriptSrc", contentName: "embed" },
        { path: "/norwegian/:userId/:playerId/:scriptSrc", contentName: "norwegian" },
    ]);

}
