import React from 'react'
import { WappContext, withWapp } from 'wapplr-react/dist/common/Wapp';
import hosts from '../config/hosts'

class CreateDemoPlayerExample extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.refElements = {}

        this.state = {
            serviceHost: hosts[0],
            emailAddress: 'karesz.carco+apitest@gmail.com',
            password: 'apitest',
            serverResponse: '',
            shareCode: '',
            postId: '',
            dataId: '',
            scriptSrc: '',
            plusdata: this.getNewPlusData(),
            coproducers: '',
            workstate: 0
        }
    }

    getNewPlusData() {
        return 'test_' + new Date().getTime().toString()
    }

    touchEnd = (e, fn, pt, d) => {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    }

    click = async (e, d) => {

        const { apiType = 'example' } = this.props
        const { type } = d
        const getNewPlusData = this.getNewPlusData
        e.preventDefault()

        const {
            serviceHost,
            emailAddress,
            password,
            plusdata,
            coproducers,
            workstate
        } = this.state

        if (type === 'testEmbedPlayer') {
            const { dataId } = this.state
            const scriptSrc = serviceHost === "http://localhost:80" ? "http://localhost:3000/assets/static.js" : this.state.scriptSrc;
            if (dataId && scriptSrc) {
                window.location.href = '/embed/' + dataId + '/' + encodeURIComponent(scriptSrc) + '/'
            }
        }

        if (type === 'openPlayerAndSave') {

            await this.setState({
                serverResponse: 'please wait...',
                shareCode: '',
                postId: '',
                dataId: '',
                scriptSrc: ''
            })

            const fetchResponse = await fetch('/api/' + apiType + '/openandsave', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    sendData: {
                        serviceHost,
                        emailAddress,
                        password,
                        plusdata,
                        coproducers,
                        workstate
                    }
                }),
                credentials: 'same-origin'
            })

            let response = (fetchResponse && fetchResponse.json) ? await fetchResponse.json() : null
            if (response.data) response = response.data

            console.log(response)

            this.setState({
                serverResponse: JSON.stringify(response),
                shareCode: (response && response.response && response.response.post && response.response.post.shareCode) ? response.response.post.shareCode : '',
                postId: (response && response.response && response.response.post && response.response.post.id) ? response.response.post.id : '',
                dataId: (response && response.response && response.response.post && response.response.post.dataId) ? response.response.post.dataId : '',
                scriptSrc: (response && response.response && response.response.post && response.response.post.scriptSrc) ? response.response.post.scriptSrc : '',
                coproducers: (response && response.response && response.response.post && response.response.post.coproducers) ? response.response.post.coproducers : '',
                workstate: (response && response.response && response.response.post && response.response.post.workstate) ? response.response.post.workstate : 0,
                plusdata: (response && response.response && response.response.post && response.response.post.plusdata) ? response.response.post.plusdata : (response && response.response && response.response.post && response.response.post.id) ? '' : plusdata
            })

        }

        if (type === 'openPlayer') {

            await this.setState({
                serverResponse: 'please wait...',
                shareCode: '',
                postId: '',
                dataId: '',
                scriptSrc: ''
            })

            const fetchResponse = await fetch('/api/' + apiType + '/open', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    sendData: {
                        serviceHost,
                        emailAddress,
                        password,
                        plusdata,
                        coproducers,
                        workstate
                    }
                }),
                credentials: 'same-origin'
            })

            let response = (fetchResponse && fetchResponse.json) ? await fetchResponse.json() : null
            if (response.data) response = response.data

            console.log(response)

            this.setState({
                serverResponse: JSON.stringify(response),
                shareCode: (response && response.response && response.response.post && response.response.post.shareCode) ? response.response.post.shareCode : '',
                postId: (response && response.response && response.response.post && response.response.post.id) ? response.response.post.id : '',
                dataId: (response && response.response && response.response.post && response.response.post.dataId) ? response.response.post.dataId : '',
                scriptSrc: (response && response.response && response.response.post && response.response.post.scriptSrc) ? response.response.post.scriptSrc : '',
                coproducers: (response && response.response && response.response.post && response.response.post.coproducers) ? response.response.post.coproducers : '',
                workstate: (response && response.response && response.response.post && response.response.post.workstate) ? response.response.post.workstate : 0,
                plusdata: (response && response.response && response.response.post && response.response.post.plusdata) ? response.response.post.plusdata : (response && response.response && response.response.post && response.response.post.id) ? '' : plusdata
            })

        }

        if (type === 'createDemoPlayer') {

            await this.setState({
                serverResponse: 'please wait...',
                shareCode: '',
                postId: '',
                dataId: '',
                scriptSrc: ''
            })

            const fetchResponse = await fetch('/api/' + apiType + '/createdemoplayer', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    sendData: {
                        serviceHost,
                        emailAddress,
                        password,
                        plusdata,
                        coproducers,
                        workstate
                    }
                }),
                credentials: 'same-origin'
            })

            let response = (fetchResponse && fetchResponse.json) ? await fetchResponse.json() : null
            if (response.data) response = response.data

            console.log(response)

            this.setState({
                serverResponse: JSON.stringify(response),
                shareCode: (response && response.response && response.response.post && response.response.post.shareCode) ? response.response.post.shareCode : '',
                postId: (response && response.response && response.response.post && response.response.post.id) ? response.response.post.id : '',
                dataId: (response && response.response && response.response.post && response.response.post.dataId) ? response.response.post.dataId : '',
                scriptSrc: (response && response.response && response.response.post && response.response.post.scriptSrc) ? response.response.post.scriptSrc : '',
                coproducers: (response && response.response && response.response.post && response.response.post.coproducers) ? response.response.post.coproducers : '',
                workstate: (response && response.response && response.response.post && response.response.post.workstate) ? response.response.post.workstate : 0,
                plusdata: (response && response.response && response.response.post && response.response.post.plusdata) ? response.response.post.plusdata : (response && response.response && response.response.post && response.response.post.id) ? '' : plusdata
            })

        }

    }

    inputChange = ({ name }) => {
        const input = this.refElements[name]
        const { password } = this.state
        if (this.state[name] !== input.value) {
            this.setState({
                [name]: input.value
            })
        }
    }

    setRef = (a, e) => {
        this.refElements[a] = e
    }

    render() {

        const touchEnd = this.touchEnd
        const inputChange = this.inputChange
        const setRef = this.setRef

        const {
            password,
            serviceHost,
            emailAddress,
            serverResponse,
            dataId,
            plusdata,
            coproducers,
            workstate,
            postId
        } = this.state

        const {wapp} = this.context;

        if (wapp.globals.DEV){
            hosts.push("http://localhost:80")
        }

        const scriptSrc = serviceHost === "http://localhost:80" ? "http://localhost:3000/assets/static.js" : this.state.scriptSrc;

        return (
            <div>
                <div style={{
                    width: '300px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    padding: '6px',
                    boxSizing: 'border-box',
                    backgroundColor: 'whitesmoke'
                }}>
                    <div style={{ padding: '6px', display: 'block', width: '100%', boxSizing: 'border-box' }}>
                        <label style={{ fontFamily: 'arial', fontSize: '10px' }}>Service Host</label>
                        <select
                            ref={function(e) {setRef('serviceHost', e)}}
                            value={serviceHost}
                            onChange={function() {inputChange({ name: 'serviceHost' })}}
                            style={{ width: '100%', display: 'block', boxSizing: 'border-box' }}
                        >
                            {hosts.map((host, i)=><option key={i}>{host}</option>)}
                        </select>
                    </div>
                    <div style={{ padding: '6px', display: 'block', width: '100%', boxSizing: 'border-box' }}>
                        <label style={{ fontFamily: 'arial', fontSize: '10px' }}>Email Address</label>
                        <input ref={function(e) {
                            setRef('emailAddress', e)
                        }} value={emailAddress} onChange={function(e) {
                            inputChange({ name: 'emailAddress' })
                        }}
                               style={{ width: '100%', display: 'block', boxSizing: 'border-box' }} />
                    </div>
                    <div style={{ padding: '6px', display: 'block', width: '100%', boxSizing: 'border-box' }}>
                        <label style={{ fontFamily: 'arial', fontSize: '10px' }}>Password</label>
                        <input type='password' ref={function(e) {
                            setRef('password', e)
                        }} value={password} onChange={function(e) {
                            inputChange({ name: 'password' })
                        }}
                               style={{ width: '100%', display: 'block', boxSizing: 'border-box' }} />
                    </div>
                    <div style={{ padding: '6px', display: 'block', width: '100%', boxSizing: 'border-box' }}>
                        <label style={{ fontFamily: 'arial', fontSize: '10px' }}>Save plus data (eg.:id)</label>
                        <input ref={function(e) {
                            setRef('plusdata', e)
                        }} value={plusdata} onChange={function(e) {
                            inputChange({ name: 'plusdata' })
                        }}
                               style={{ width: '100%', display: 'block', boxSizing: 'border-box' }} />
                    </div>
                    <div style={{ padding: '6px', display: 'block', width: '100%', boxSizing: 'border-box' }}>
                        <div
                            onClick={function(e) {
                                touchEnd(e, 'click', 'click', { type: 'createDemoPlayer' })
                            }}
                            style={{
                                width: '100%',
                                display: 'block',
                                padding: '6px',
                                textAlign: 'center',
                                fontFamily: 'arial',
                                fontSize: '12px',
                                backgroundColor: '#333333',
                                color: '#ffffff',
                                boxSizing: 'border-box',
                                cursor: 'pointer'
                            }}>
                            Create New Demo Player
                        </div>
                    </div>
                    {(plusdata) ?
                        <div style={{ padding: '6px', display: 'block', width: '100%', boxSizing: 'border-box' }}>
                            <div
                                onClick={function(e) {
                                    touchEnd(e, 'click', 'click', { type: 'openPlayerAndSave' })
                                }}
                                style={{
                                    width: '100%',
                                    display: 'block',
                                    padding: '6px',
                                    textAlign: 'center',
                                    fontFamily: 'arial',
                                    fontSize: '12px',
                                    backgroundColor: '#333333',
                                    color: '#ffffff',
                                    boxSizing: 'border-box',
                                    cursor: 'pointer'
                                }}>
                                {'Open player and save {plusdata:' + plusdata + '}'}
                            </div>
                        </div> : null}
                    {(serverResponse) ?
                        <div style={{ padding: '6px', display: 'block', width: '100%', boxSizing: 'border-box' }}>
                            <label style={{ fontFamily: 'arial', fontSize: '10px' }}>Server Response</label>
                            <div style={{
                                display: 'block',
                                overflow: 'hidden',
                                wordWrap: 'break-word',
                                fontSize: '12px',
                                fontFamily: 'arial',
                                maxHeight: '200px',
                                overflowY: 'auto'
                            }}>{serverResponse}</div>
                        </div> : null}
                    {(dataId && scriptSrc && postId) ?
                        <div style={{ padding: '6px', display: 'block', width: '100%', boxSizing: 'border-box' }}>
                            <a
                                href={'/embed/' + dataId + '/' + encodeURIComponent(scriptSrc) + '/'}
                                target={'_blank'}
                                style={{
                                    width: '100%',
                                    display: 'block',
                                    padding: '6px',
                                    textAlign: 'center',
                                    fontFamily: 'arial',
                                    fontSize: '12px',
                                    backgroundColor: '#333333',
                                    color: '#ffffff',
                                    boxSizing: 'border-box',
                                    cursor: 'pointer'
                                }}>
                                {'View embed player {id:' + postId + '} > test'}
                            </a>
                        </div> : null}
                </div>
            </div>
        )
    }

}

CreateDemoPlayerExample.contextType = WappContext

export default withWapp(CreateDemoPlayerExample)
