import React from 'react'
import { WappContext, withWapp } from 'wapplr-react/dist/common/Wapp'
import hosts from '../config/hosts'

class OpenById extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.refElements = {}

        this.state = {
            serviceHost: hosts[0],
            emailAddress: 'karesz.carco+apitest@gmail.com',
            password: 'apitest',
            serverResponse: '',
            shareCode: '',
            postId: '',
            dataId: '',
            scriptSrc: '',
            id: ''
        }
    }

    touchEnd = (e, fn, pt, d) => {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    }

    click = async (e, d) => {

        const { apiType = 'test' } = this.props
        const { type } = d

        e.preventDefault()

        const {
            serviceHost,
            emailAddress,
            password,
            id
        } = this.state

        if (type === 'openPlayer') {

            await this.setState({
                serverResponse: 'please wait...',
                shareCode: '',
                postId: '',
                dataId: '',
                scriptSrc: ''
            })

            const fetchResponse = await fetch('/api/' + apiType + '/openbyid', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    sendData: {
                        serviceHost,
                        emailAddress,
                        password,
                        id
                    }
                }),
                credentials: 'same-origin'
            })

            let response = (fetchResponse && fetchResponse.json) ? await fetchResponse.json() : null
            if (response.data) response = response.data

            console.log(response)

            this.setState({
                serverResponse: JSON.stringify(response),
                shareCode: (response && response.response && response.response.post && response.response.post.shareCode) ? response.response.post.shareCode : '',
                postId: (response && response.response && response.response.post && response.response.post.id) ? response.response.post.id : '',
                dataId: (response && response.response && response.response.post && response.response.post.dataId) ? response.response.post.dataId : '',
                scriptSrc: (response && response.response && response.response.post && response.response.post.scriptSrc) ? response.response.post.scriptSrc : '',
                id: (response && response.response && response.response.post && response.response.post.id) ? response.response.post.id : (response && response.response && response.response.post && response.response.post.id) ? '' : id
            })

        }

    }

    inputChange = ({ name }) => {
        const input = this.refElements[name]
        const { password } = this.state
        if (this.state[name] !== input.value) {
            this.setState({
                [name]: input.value
            })
        }
    }

    setRef = (a, e) => {
        this.refElements[a] = e
    }

    render() {

        const touchEnd = this.touchEnd
        const inputChange = this.inputChange
        const setRef = this.setRef
        const { password, serviceHost, emailAddress, serverResponse, id } = this.state

        const {wapp} = this.context;

        if (wapp.globals.DEV){
            hosts.push("http://localhost:80")
        }

        return (
            <div>
                <div style={{
                    width: '300px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    padding: '6px',
                    boxSizing: 'border-box',
                    backgroundColor: 'whitesmoke'
                }}>
                    <div style={{ padding: '6px', display: 'block', width: '100%', boxSizing: 'border-box' }}>
                        <label style={{ fontFamily: 'arial', fontSize: '10px' }}>Service Host</label>
                        <select
                            ref={function(e) {setRef('serviceHost', e)}}
                            value={serviceHost}
                            onChange={function() {inputChange({ name: 'serviceHost' })}}
                            style={{ width: '100%', display: 'block', boxSizing: 'border-box' }}
                        >
                            {hosts.map((host, i)=><option key={i}>{host}</option>)}
                        </select>
                    </div>
                    <div style={{ padding: '6px', display: 'block', width: '100%', boxSizing: 'border-box' }}>
                        <label style={{ fontFamily: 'arial', fontSize: '10px' }}>Email Address</label>
                        <input ref={function(e) {
                            setRef('emailAddress', e)
                        }} value={emailAddress} onChange={function(e) {
                            inputChange({ name: 'emailAddress' })
                        }}
                               style={{ width: '100%', display: 'block', boxSizing: 'border-box' }} />
                    </div>
                    <div style={{ padding: '6px', display: 'block', width: '100%', boxSizing: 'border-box' }}>
                        <label style={{ fontFamily: 'arial', fontSize: '10px' }}>Password</label>
                        <input type='password' ref={function(e) {
                            setRef('password', e)
                        }} value={password} onChange={function(e) {
                            inputChange({ name: 'password' })
                        }}
                               style={{ width: '100%', display: 'block', boxSizing: 'border-box' }} />
                    </div>
                    <div style={{ padding: '6px', display: 'block', width: '100%', boxSizing: 'border-box' }}>
                        <label style={{ fontFamily: 'arial', fontSize: '10px' }}>ID</label>
                        <input ref={function(e) {
                            setRef('id', e)
                        }} value={id} onChange={function(e) {
                            inputChange({ name: 'id' })
                        }}
                               style={{ width: '100%', display: 'block', boxSizing: 'border-box' }} />
                    </div>
                    <div style={{ padding: '6px', display: 'block', width: '100%', boxSizing: 'border-box' }}>
                        <div
                            onClick={function(e) {
                                touchEnd(e, 'click', 'click', { type: 'openPlayer' })
                            }}
                            style={{
                                width: '100%',
                                display: 'block',
                                padding: '6px',
                                textAlign: 'center',
                                fontFamily: 'arial',
                                fontSize: '12px',
                                backgroundColor: '#333333',
                                color: '#ffffff',
                                boxSizing: 'border-box',
                                cursor: 'pointer'
                            }}>
                            Open player
                        </div>
                    </div>
                    {(serverResponse) ?
                        <div style={{ padding: '6px', display: 'block', width: '100%', boxSizing: 'border-box' }}>
                            <label style={{ fontFamily: 'arial', fontSize: '10px' }}>Server Response</label>
                            <div style={{
                                display: 'block',
                                overflow: 'hidden',
                                wordWrap: 'break-word',
                                fontSize: '12px',
                                fontFamily: 'arial',
                                maxHeight: '200px',
                                overflowY: 'auto'
                            }}>{serverResponse}</div>
                        </div> : null}
                </div>
            </div>
        )
    }
}

OpenById.contextType = WappContext

export default withWapp(OpenById)
