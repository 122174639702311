import React from 'react'
import { WappContext, withWapp } from 'wapplr-react/dist/common/Wapp'

class Norwegian extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.refElements = {}

        this.state = {
            dataId: '',
            scriptUrl: '',
            script: null
        }

    }

    async componentDidMount() {

        const paths = window.location.pathname.split('/')
        let scriptUrl = paths[4]
        scriptUrl = decodeURIComponent(scriptUrl)

        if (paths[2] && paths[3] && scriptUrl) {

            await this.setState({
                dataId: paths[2] + '/' + paths[3],
                scriptUrl: scriptUrl
            })

            const script = document.createElement('script')
            script.setAttribute('src', scriptUrl)
            script.setAttribute('async', '')
            script.async = true

            document.body.appendChild(script)

        }

    }

    render() {

        const { dataId } = this.state

        return (
            <div>
                <div
                    id={'flatchooser-unique-id'}
                    data-app='flatchooser'
                    data-id={dataId}

                    data-autoload='1'
                    data-disablegraphql='1'
                    data-loadfromstatic='1'

                    data-template='design2'

                    data-translations="[
                            {'text': 'available', 'translate': 'Ledig'},
                            {'text': 'reserved','translate': 'Reserved'},
                            {'text': 'sold', 'translate': 'Solgt'},
                            {'text': 'download','translate': 'Last ned'},
                            {'text': 'info', 'translate': 'Info'},
                            {'text': 'previously','translate': 'Tidligere'},
                            {'text': 'filter', 'translate': 'Filter'},
                            {'text': 'area filter','translate': 'Område filter'},
                            {'text': 'building filter', 'translate': 'Bygg filter'},
                            {'text': 'flat filter','translate': 'Leilighet filter'},
                            {'text': 'share', 'translate': 'Del'},
                            {'text': 'selected view','translate': 'Valgt visning'},
                            {'text': 'player link', 'translate': 'Player lenken'},
                            {'text': 'embed','translate': 'Norwegian'},
                            {'text': 'tokens', 'translate': 'Tokens'},
                            {'text': 'Object name','translate': 'Enhetsnavn'},
                            {'text': 'Status', 'translate': 'Status'},
                            {'text': 'Number of flats','translate': 'Antall enheter'},
                            {'text': 'Price', 'translate': 'Pris'},
                            {'text': 'Floor', 'translate': 'Etasje'},
                            {'text': 'Size','translate': 'BRA'},
                            {'text': 'Rooms', 'translate': 'Soverom'},
                            {'text': 'PDF download', 'translate': 'Las ned plantekning'},
                            {'text': 'Primary area','translate': 'P-ROM'},
                            {'text': 'sguid','translate': 'ID'},
                            {'text': 'Make an offer','translate': 'Gi bud'},
                            {'text': 'open','translate': 'Åpen'},
                            {'text': 'First name','translate': 'Fornavn'},
                            {'text': 'Last name','translate': 'Etternavn'},
                            {'text': 'E-mail','translate': 'E-mail'},
                            {'text': 'Phone','translate': 'Telefonnummer'},
                            {'text': 'Address','translate': 'Adresse'},
                            {'text': 'Zip','translate': 'Post kode'},
                            {'text': 'City','translate': 'By'},
                            {'text': 'submit','translate': 'sende'},
                            {'text': 'cancel','translate': 'avbryt'},
                            {'text': 'close','translate': 'lukke'},
                            {'text': 'Invalid data','translate': 'Ugyldig data'},
                            {'text': 'Thank you for ordering the PDF','translate': 'Takk for at du bestilte PDF'},
                            {'text': 'project not active','translate': 'Prosjektet er ikke aktivt'},
                            {'text': 'Interest','translate': 'Meld interesse'},
                            {'text': 'Privacy policy','translate': 'Personvernpolicy'},
                            {'text': 'Home','translate': 'Hjem'},
                            {'text': 'Price list','translate': 'Prisliste'},
                            {'text': 'Contact','translate': 'Kontakt oss'}
                        ]"
                    style={{
                        width: '100%',
                        maxWidth: '100%',
                        height: 'auto',
                        display: 'block',
                        position: 'relative',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                ></div>
            </div>
        )
    }

}

Norwegian.contextType = WappContext

export default withWapp(Norwegian)
