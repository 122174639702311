import wapplrClient from 'wapplr'
import React from 'react'
import wapplrReact from 'wapplr-react';

import setContents from '../common/setContents'
import {getConfig as getCommonConfig} from "../common/config";

export function getConfig(p = {}) {

    const {config = {}} = p;

    const clientConfig = config.client || {};
    const commonConfig = getCommonConfig(p).config;

    const common = {...commonConfig.common};

    const client = {
        ...clientConfig,
        disableUseDefaultMiddlewares: true
    };

    return {
        config: {
            ...config,
            common: common,
            client: client,
        },
    }

}

export default function createClient(p) {

    const {config} = getConfig(p);
    const wapp = p.wapp || wapplrClient({...p, config});

    wapplrReact({wapp});
    setContents({wapp});

    return wapp;
}

export function createMiddleware(p = {}) {
    return async function middleware(req, res, next) {
        const wapp = req.wapp || p.wapp || createClient(p).wapp
        next()
    }
}

const defaultConfig = {
    config: {
        globals: {
            DEV: (typeof DEV !== 'undefined') ? DEV : undefined,
            WAPP: (typeof WAPP !== 'undefined') ? WAPP : undefined,
            RUN: (typeof RUN !== 'undefined') ? RUN : undefined,
            TYPE: (typeof TYPE !== 'undefined') ? TYPE : undefined,
            ROOT: (typeof ROOT !== 'undefined') ? ROOT : '/',
            NAME: (typeof NAME !== 'undefined') ? NAME : undefined
        }
    }
}

export function run(p = defaultConfig) {

    if (p?.config?.globals && !p.config.globals.RUN) {
        p.config.globals.RUN = p.config?.globals.NAME || 'flatchooser-helpers'
    }

    const {config} = getConfig(p);
    const wapp = createClient({...p, config });

    const globals = wapp.globals;
    const {DEV} = globals;

    const app = wapp.client.app;

    app.use(wapp.client.middlewares.wapp);

    app.use(createMiddleware({wapp, ...p}));

    app.use([
        ...Object.keys(wapp.client.middlewares).map(function (key){
            return (key === "wapp") ? function next(req, res, next) { return next(); } : wapp.client.middlewares[key];
        })
    ]);

    wapp.client.listen()

    if (typeof DEV !== 'undefined' && DEV && module.hot) {
        app.hot = module.hot
        module.hot.accept()
    }

    return wapp
}

if (typeof RUN !== 'undefined' && RUN === 'flatchooser-helpers') {
    run()
}
